.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

h2 {
	padding: 1rem;
}

.generations.panel {
	position: fixed;
	bottom: 0;
	overflow-x: auto;
}

.modal-ext {
	position: fixed;
	top: 0;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(4px);
	background: #00000030;
	z-index: 9999;
	left: 0;
	padding: -1rem;
}

small {
	margin-top: 0.5rem;
	margin-bottom: 0.25rem;
}

.top-right-icon {
	position: fixed;
	padding: 0.5rem;
	font-size: 20px;
	color: whitesmoke;
	z-index: 9999;
}

.myimgpan {
	overflow: auto;
	display: flex;
	flex-direction: column;
	height: 80vh;
}
.gentext {
	background: linear-gradient(90deg, #12c2e9 0%, #c471ed 50%, #f64f59 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: bold;
	font-size: 36px;
}

.lds-dual-ring {
	display: inline-block;
	width: 18px;
	height: 18px;
  }
  .lds-dual-ring:after {
	content: " ";
	display: block;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	border: 1px solid #fff;
	border-color: #fff transparent #fff transparent;
	animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  