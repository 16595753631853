body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: radial-gradient(#ffffff, #00000008);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
	padding: 0.5rem 1rem;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	margin-left: 1rem;
}

button:hover {
	background-color: #ccc;
}

button:active {
	transform: scale(0.95);
}

.pointer {
	cursor: pointer;
	transition: all 0.2s !important;
}

.pointer:hover {
	transform: scale(1.03);
}

.card-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.5;
}

.card {
	position: relative;
}

.card-picker {
	max-width: 300px;
	aspect-ratio: 1/1;
	margin-bottom: 1rem;
	border-radius: 10px;
	overflow: hidden;
}

.selected {
	background-color: #e14eca;
	box-shadow: 2px 4px 7px 4px #0000000d;
	border: 4px solid #a400ff;
}

.card-picker .card-body {
	align-items: center;
	justify-content: center;
	display: flex;
}

.picker-card-text {
	font-size: 2rem;
	color: white;
	position: absolute;
	font-weight: bold;
}

h5 {
	margin-top: -0.5rem;
}

.search-btn {
	margin: 0px;
	border-left: 0px;
	border-radius: 0px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.containererr {
	width: 56px;
	height: 56px;
	border-radius: 100%;
	background: linear-gradient(to right, #9b4dca, #e91e63);
	position: relative;
	margin: auto;
}

.zoom-in {
	-webkit-animation: scale-in-center 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: scale-in-center 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-center {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes scale-in-center {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}

.loader:before {
	position: absolute;
	content: '';
	width: 100%;
	left: 0;
	height: 100%;
	border-radius: 100%;
	border-bottom: 0 solid #ffffff05;

	box-shadow: 0 -10px 20px 20px #ffffff40 inset, 0 -5px 15px 10px #ffffff50 inset, 0 -2px 5px #ffffff80 inset,
		0 -3px 2px #ffffffbb inset, 0 2px 0px #ffffff, 0 2px 3px #ffffff, 0 5px 5px #ffffff90, 0 10px 15px #ffffff60,
		0 10px 20px 20px #ffffff40;
	filter: blur(3px);
	animation: 2s rotate linear infinite;
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

.generations-panel {
	position: fixed;
	bottom: 0;
	overflow-y: hidden;
	overflow-x: auto;
	display: flex;
	left: 0px;
	padding: 1rem;
	z-index: 1;
}

.modal {
	display: block;
	background-color: white;
	max-height: 100svh;
	overflow: auto;
}

.close-btn {
	font-size: 20px;
	font-weight: bold;
	color: #fff;
	background-color: #000;
	border: none;
	border-radius: 50%;
	padding: 5px;
	width: 42px;
	cursor: pointer;
	margin-right: 1.5rem;
}
.modal-close {
	font-size: 20px;
	font-weight: bold;
	color: #fff;
	background-color: #000;
	border: none;
	border-radius: 50%;
	padding: 5px;
	width: 42px;
	cursor: pointer;
	margin-right: 1.5rem;
	position: absolute;
	right: 0;
	top: 1rem;
	max-height: 100svh;
	overflow: auto;
}

.community-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	grid-gap: 0.5rem;
	padding: 0.5rem;
	background-color: #fbfbfb;
}

@media (max-width: 768px) {
	.community-grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
		grid-gap: 0.25rem;
		padding: 0.25rem;
	}
}

.community-grid-item {
	background-color: #fff;
	border-radius: 5px;
	padding: 0.5rem;
	max-height: 200px;
}
.community-grid-item img {
	height: 100%;
}

.tiny-remove {
	font-size: 11px;
	font-weight: bold;
	color: #fff;
	background-color: #000;
	border: none;
	border-radius: 50%;
	padding: 3px;
	width: 20px;
	line-height: 12px;
	height: 20px;
	cursor: pointer;
	z-index: 1;
}

.v-unipanel {
	display: flex;
	max-height: calc(100svh - 90px);
	flex-direction: row;
	flex-wrap: wrap;
}

.v-unipanel:hover {
	overflow: auto;
}

@media (max-width: 1023px) {
	.v-unipanel {
		overflow: auto;
		flex-wrap: nowrap;
	}

	.sm-flex-1 {
		flex: 1;
	}
}

@media (min-width: 1024px) {
	.d-flex-md {
		display: flex;
	}
}

.flex-1 {
	flex: 1;
}

.winki {
	background: #cb60b3;
	background: linear-gradient(to right, #cb60b3 0%, #c146a1 50%, #a80077 51%, #db36a4 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.asp {
	aspect-ratio: 1/2;
}

nav {
	backdrop-filter: blur(10px);
}

.card-register {
	max-width: 500px;
	position: relative;
}

.card-register .closei-btn {
	position: absolute;
	right: 1rem;
	top: 0.5rem;
	cursor: pointer;
	font-size: 18px;
}

.add-base {
	width: 46px;
	height: 67px;
	border-radius: 4px;
	margin-top: 0.6rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background: #ffffff1c;
}

.v-special-wrap {
	flex-wrap: wrap;
	max-height: calc(100svh - 245px);
	justify-content: center;
}

@media (min-width: 1024px) {
	:-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: #f5f5f5;
	}

	::-webkit-scrollbar {
		width: 10px;
		background-color: #4e4e4e33;
	}

	::-webkit-scrollbar-thumb {
		background-color: #0e011591;
	}
}
